import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import getCookie from "@utils/getCookie";
import GlobalStyle, { primaryFontFamily } from "./GlobalStyle";
import { DEFAULT_COLOR_VARIANTS, CR_LITE_COLOR_VARIANTS, LOFT_COLOR_VARIANTS } from "./colors";
import { DEVICE_SIZES } from "../constants/styleConstants";

const loftEnabled = getCookie("new_loft") === "new";

const ThemeWrapper = (props) => {
  const { crLite } = props.property;
  const whiteLabel = {
    primary: global?.window?.white_label_primary || false,
    secondary: global?.window?.white_label_secondary || false,
  };

  let colorVariants = DEFAULT_COLOR_VARIANTS;

  if (crLite) {
    colorVariants = CR_LITE_COLOR_VARIANTS;
  }

  if (loftEnabled) {
    colorVariants = LOFT_COLOR_VARIANTS;
  }

  const formatHexColor = (color) => `#${color}`;
  const mainThemeColor = (color, name) => {
    if (!color) return colorVariants[name];

    if (whiteLabel[`${name}`]) {
      return formatHexColor(whiteLabel[`${name}`]);
    }
    return props.whiteLabel?.enabled ? formatHexColor(color) : colorVariants[name];
  };

  const defaultTheme = createTheme();

  const theme = createTheme({
    breakpoints: {
      values: {
        ...defaultTheme.breakpoints.values,
        md: DEVICE_SIZES.mobile,
        lg: DEVICE_SIZES.desktop,
      },
    },
    components: {
      MuiTypography: {
        styleOverrides: {
          root: {
            display: "block",
          },
          h4: {
            fontSize: "2rem",
          },
          h6: {
            fontSize: "1.25rem",
          },
          body1: {
            fontSize: "14px",
          },
          body2: {
            fontSize: "12px",
          },
        },
      },
      MuiButtonBase: {
        defaultProps: {
          TouchRippleProps: {
            style: {
              color: mainThemeColor(props.whiteLabel?.lightGreyColor, "lightGrey"),
            },
          },
        },
      },
      MuiButton: {
        styleOverrides: {
          root: ({ ownerState }) => ({
            ...(ownerState.variant === "contained" && {
              borderRadius: "8px",
            }),
            ...(ownerState.variant === "outlined" && {
              borderRadius: "8px",
              boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.04)",
              borderColor: mainThemeColor(props.whiteLabel?.lightGreyColor, "lightGrey"),
            }),
            ...(ownerState.disabled && {
              borderRadius: "8px",
            }),
          }),
        },
      },
    },
    typography: {
      fontFamily: primaryFontFamily,
      useNextVariants: true,
      h3: {
        fontWeight: "700",
      },
      h4: {
        fontWeight: "900",
      },
      h5: {
        fontWeight: "900",
      },
      h6: {
        fontWeight: "900",
      },
      subtitle1: {
        fontWeight: "900",
      },
      subtitle2: {
        fontWeight: "900",
      },
      button: {
        fontWeight: "900",
      },
      overline: {
        fontWeight: "700",
      },
    },
    palette: {
      primary: {
        main: mainThemeColor(props.whiteLabel?.primaryColor, "primary"),
      },
      secondary: {
        main: mainThemeColor(props.whiteLabel?.secondaryColor, "secondary"),
        contrastText: "#FFF",
      },
      error: {
        main: colorVariants?.error || mainThemeColor(props.whiteLabel?.alertColor, "alert"),
      },
      text: {
        primary: colorVariants.textPrimary,
        secondary: colorVariants.textSecondary,
      },
      alert: {
        main: mainThemeColor(props.whiteLabel?.alertColor, "alert"),
      },
      urgent: {
        main: mainThemeColor(props.whiteLabel?.urgentColor, "urgent"),
      },
      success: {
        main: mainThemeColor(props.whiteLabel?.successColor, "success"),
      },
      amber: {
        main: mainThemeColor(props.whiteLabel?.amberColor, "amber"),
      },
      warning: {
        main: mainThemeColor(props.whiteLabel?.warningColor, "warning"),
      },
      backgroundColor: {
        main: mainThemeColor(props.whiteLabel?.backgroundColor, "background"),
      },
      mediumGrey: {
        main: mainThemeColor(props.whiteLabel?.mediumGreyColor, "mediumGrey"),
      },
      lightGrey: {
        main: mainThemeColor(props.whiteLabel?.lightGreyColor, "lightGrey"),
      },
      crGreen: {
        main: mainThemeColor(props.whiteLabel?.crGreenColor, "crGreen"),
      },
      darkGrey: {
        main: mainThemeColor(props.whiteLabel?.darkGreyColor, "darkGrey"),
      },
      rpOrange: {
        main: mainThemeColor(props.whiteLabel?.rpOrangeColor, "rpOrange"),
      },
      modernBlack: {
        main: mainThemeColor(props.whiteLabel?.modernBlackColor, "modernBlack"),
      },
      blueGray: {
        main: mainThemeColor(props.whiteLabel?.blueGrayColor, "blueGray"),
        secondary: mainThemeColor(props.whiteLabel?.blueGrayColor, "lightBlueGray"),
      },
      purpleGray: {
        main: mainThemeColor(props.whiteLabel?.purpleGrayColor, "purpleGray"),
        secondary: mainThemeColor(props.whiteLabel?.purpleGrayColor, "lightPurpleGray"),
      },
      warmYellow: {
        main: mainThemeColor(props.whiteLabel?.warmYellowColor, "warmYellow"),
        secondary: mainThemeColor(props.whiteLabel?.warmYellowColor, "lightYellow"),
      },
      crLiteBackground: {
        main: "#FFF",
      },
      infoColor: {
        main: colorVariants.infoColor,
      },
    },
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <Fragment>
          <GlobalStyle crlite={crLite} />
          {props.children}
        </Fragment>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

ThemeWrapper.propTypes = {
  children: PropTypes.node,
  property: PropTypes.shape({
    crLite: PropTypes.bool,
  }),
  whiteLabel: PropTypes.shape({
    enabled: PropTypes.bool,
    primaryColor: PropTypes.string,
    secondaryColor: PropTypes.string,
    alertColor: PropTypes.string,
    urgentColor: PropTypes.string,
    successColor: PropTypes.string,
    amberColor: PropTypes.string,
    warningColor: PropTypes.string,
    backgroundColor: PropTypes.string,
    mediumGreyColor: PropTypes.string,
    lightGreyColor: PropTypes.string,
    crGreenColor: PropTypes.string,
    darkGreyColor: PropTypes.string,
    rpOrangeColor: PropTypes.string,
    modernBlackColor: PropTypes.string,
    blueGrayColor: PropTypes.string,
    warmYellowColor: PropTypes.string,
    purpleGrayColor: PropTypes.string,
    infoColor: PropTypes.string,
  }),
};

ThemeWrapper.defaultProps = {
  children: null,
  property: {
    crLite: false,
  },
  whiteLabel: {},
};

export default ThemeWrapper;
export const ThemeWrapperForStorybook = ThemeWrapper;
