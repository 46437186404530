import React from "react";
import Typography, { TypographyTypeMap } from "@mui/material/Typography";
import withTheme from "@mui/styles/withTheme";
import { useTheme } from "@mui/material/styles";

interface MaterialTypographyProps extends Partial<TypographyTypeMap> {
  color?: string;
  variant?:
    | "button"
    | "caption"
    | "h1"
    | "h2"
    | "h3"
    | "h4"
    | "h5"
    | "h6"
    | "subtitle1"
    | "subtitle2"
    | "body1"
    | "body2"
    | "overline"
    | "inherit";
  style?: React.CSSProperties;
  children?: any;
  align?: "inherit" | "left" | "right" | "center" | "justify";
  component?: string;
  size?: string;
  className?: string;
}

const MaterialTypography = (props: MaterialTypographyProps) => {
  const theme = useTheme();

  const style = {
    ...props.style,
  };
  switch (props.color) {
    case "success":
    case "alert":
    case "urgent":
    case "warning":
    case "amber":
    case "lightGrey":
    case "mediumGrey":
    case "modernBlack":
    case "rpOrange": {
      const { color, ...otherProps } = props;
      const updatedStyle = {
        ...style,
        color: theme?.palette[color].main,
      };
      return <Typography {...otherProps} style={updatedStyle} />;
    }
    case "contrastPrimary": {
      const updatedStyle = {
        ...style,
        color: theme?.palette.primary.contrastText,
      };
      return <Typography {...props} style={updatedStyle} />;
    }
    case "contrastSecondary": {
      const updatedStyle = {
        ...style,
        color: theme?.palette.secondary.contrastText,
      };
      return <Typography {...props} style={updatedStyle} />;
    }
    default:
  }

  return <Typography {...props} />;
};

MaterialTypography.defaultProps = {
  color: "textPrimary",
  style: undefined,
  children: undefined,
  variant: "body1",
  align: undefined,
};

export default withTheme(MaterialTypography);
