// eslint-disable-next-line
import { css, CSSProp, FlattenSimpleInterpolation } from "styled-components";
import { DEVICE_SIZES } from "../constants/styleConstants";

interface TruncateProps {
  width: string;
}

export function truncate({ width }: TruncateProps): CSSProp {
  return css`
    width: ${width};
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  `;
}

interface FontProps {
  family: string;
  weight: string | number;
  size: string;
}

export function setFont({ family, weight, size }: FontProps): CSSProp {
  return css`
    font-family: ${family};
    font-weight: ${weight};
    font-size: ${size};
  `;
}

interface BoxModelProps {
  margin?: string;
  marginTop?: string;
  marginBottom?: string;
  marginLeft?: string;
  marginRight?: string;
  padding?: string;
  paddingTop?: string;
  paddingBottom?: string;
  paddingLeft?: string;
  paddingRight?: string;
  border?: string;
  borderTop?: string;
  borderBottom?: string;
  borderLeft?: string;
  borderRight?: string;
  borderRadius?: string;
}

/**
 * Utility to add box model props to styled components
 * @param {BoxModelProps} props
 */
export function boxModel(props: BoxModelProps): CSSProp {
  return css`
    margin: ${props.margin};
    margin-top: ${props.marginTop};
    margin-bottom: ${props.marginBottom};
    margin-left: ${props.marginLeft};
    margin-right: ${props.marginRight};

    padding: ${props.padding};
    padding-top: ${props.paddingTop};
    padding-bottom: ${props.paddingBottom};
    padding-left: ${props.paddingLeft};
    padding-right: ${props.paddingRight};

    border: ${props.border};
    border-top: ${props.borderTop};
    border-bottom: ${props.borderBottom};
    border-left: ${props.borderLeft};
    border-right: ${props.borderRight};

    border-radius: ${props.borderRadius};
  `;
}

interface FlexModelProps {
  display?: string;
  flexDirection?: string;
  flexWrap?: string;
  flexFlow?: string;
  justifyContent?: string;
  alignItems?: string;
  alignContent?: string;
  order?: string;
  flexGrow?: string;
  flexShrink?: string;
  flexBasis?: string;
  flex?: string;
  alignSelf?: string;
}

/**
 * Utility to add flex props to styled components
 * @param {FlexModelProps} props
 */
export function flexModel(props: FlexModelProps): CSSProp {
  return css`
    display: ${props.display};
    flex-direction: ${props.flexDirection};
    flex-wrap: ${props.flexWrap};
    flex-flow: ${props.flexFlow};
    justify-content: ${props.justifyContent};
    align-items: ${props.alignItems};
    align-content: ${props.alignContent};

    order: ${props.order};
    flex-grow: ${props.flexGrow};
    flex-shrink: ${props.flexShrink};
    flex-basis: ${props.flexBasis};
    flex: ${props.flex};
    align-self: ${props.alignSelf};
  `;
}

type Media = {
  [label: string]: (...args: TemplateStringsArray[]) => FlattenSimpleInterpolation;
};

// iterate through the DEVICE_SIZES and create a media template
export const media: Media = Object.keys(DEVICE_SIZES).reduce((acc, label) => {
  const accumulator = acc;
  // use em in breakpoints to work properly cross-browser and support users
  // changing their browsers font-size: https://zellwk.com/blog/media-query-units/
  const emSize = DEVICE_SIZES[label] / 16;
  accumulator[label] = (...args) => css`
    @media (max-width: ${emSize}em) {
      ${css(...args)};
    }
  `;

  return accumulator;
}, {} as Media);

/**
 *
 * @param {boolean} scroll Sets the overflow property on the body to hidden or auto
 */
export function scrollBody(scroll: boolean): void {
  if (!scroll) {
    document.querySelector("body")!.style.overflow = "hidden";
    return;
  }

  document.querySelector("body")!.style.overflow = "auto";
}

export function resetScroll(): void {
  document.querySelector("body")!.scrollTop = 0;
}

/**
 * Center an item when you don't know the height or width
 */
export function center(): CSSProp {
  return css`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  `;
}

export default {
  truncate,
  media,
  setFont,
  scrollBody,
  resetScroll,
  center,
};
