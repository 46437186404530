import React from "react";
import Typography from "./material/MaterialTypography";

interface ErrorTextProps {
  text: string | boolean | null;
  style: React.CSSProperties;
  align?: "inherit" | "left" | "right" | "center" | "justify";
}

const ErrorText: React.FC<ErrorTextProps> = ({ text = null, style = undefined, align = "left" }) => (
  <>
    {text && (
      <Typography color="alert" variant="caption" style={style} align={align}>
        {text}
      </Typography>
    )}
  </>
);

export default ErrorText;
