// eslint-disable-next-line
import { createGlobalStyle } from "styled-components";
import styledNormalize from "styled-normalize";
import { COLORS, DEFAULT_COLOR_VARIANTS } from "./colors";

export const primaryFontFamily = "Lato, sans-serif";

/**
 * @component GlobalStyle Stylesheet for Apps
 */

const GlobalStyle = createGlobalStyle`
  ${styledNormalize}

  body {
    font-size: 16px;
    font-family: ${primaryFontFamily};
    -webkit-font-smoothing: antialiased;
    box-sizing: border-box;
    background-color: ${(props) => (props.crlite ? "white" : COLORS.white)};
    min-height: 100%;
  }

  * {
    box-sizing: border-box;
  }

  a {
    color: ${DEFAULT_COLOR_VARIANTS.secondary};
    cursor: pointer;
    text-decoration: none;
  }

  a:focus {
    outline: ${DEFAULT_COLOR_VARIANTS.secondary} auto 1px;
  }

  .Nav {
    padding: 0 0.5rem;
    text-decoration: none;
    color: ${COLORS.white};
    opacity: 0.6;

    > svg {
      fill: ${COLORS.white};
    }

    &:hover {
      opacity: 1;
    }
  }
  .Nav.is-active {
    color: ${COLORS.white};
    opacity: 1;

    h4 {
      display: block;
    }
  }
  .acsb-trigger {
    visibility: hidden !important;
  }
  .acsb-trigger-icon {
    display: none !important;
  }

  .MuiTablePagination-toolbar p {
    margin: 0 !important;
  }
`;

export default GlobalStyle;
