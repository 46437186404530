import React from "react";
import { styled } from "@mui/material/styles";

const StyledImg = styled("img")`
  width: ${(p) => p.width || "100%"};
  margin: 0 auto;
  display: block;
`;

interface ImgProps {
  src?: React.ReactNode | string;
  alt?: string;
  width?: string;
}

const Img: React.FC<ImgProps> = ({ src = "", alt = "", ...rest }) => <StyledImg src={src} alt={alt} {...rest} />;

export default Img;
